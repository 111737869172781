import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/link-context';

import { auth } from '../session/api';
import { general } from '../../configs';

const HOST = general.GQL_HTTP_PATH;

const httpLink = createUploadLink({
  uri: HOST,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await auth.currentUser?.getIdToken(false);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
      'x-mz-api-feature': 'tables'
    }
  };
});

export default authLink.concat(httpLink);
