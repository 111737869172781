import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  EmailAuthProvider,
  updateEmail,
  linkWithCredential,
  unlink,
  reauthenticateWithCredential,
  updatePassword
} from 'firebase/auth';

import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import LinkEmailProviderModal from './LinkEmailProviderModal';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePasswordModal from './ChangePasswordModal';
import { AuthProviderContainer } from '../styles';

import {
  openAppSnackbarNotification
} from '../../../../services/snackbar-notifications/actions';
import { auth, sendEmailVerification } from '../../../../services';
import { routes } from '../../../../services/session/constants';

const mapDispatchToProps = {
  openAppSnackbarNotification
};

function PasswordProvider({
  data,
  allowUnlink,
  onProvidersChanged,
  openAppSnackbarNotification
}) {
  const [connectEmailDialogOpen, setConnectEmailDialogOpen] = useState(false);
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);

  const handleConnectEmailDialogOpen = useCallback(() => {
    setConnectEmailDialogOpen(true);
  }, []);

  const handleConnectEmailDialogClose = useCallback(() => {
    setConnectEmailDialogOpen(false);
  }, []);

  const handleConnectEmailDialogSubmit = useCallback(async ({ email, password }) => {
    const credential = EmailAuthProvider.credential(
      email,
      password
    );

    handleConnectEmailDialogClose();

    try {
      await linkWithCredential(auth.currentUser, credential);

      onProvidersChanged();
    } catch (e) {
      console.log(e);

      openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      });
    }
  }, [
    handleConnectEmailDialogClose,
    onProvidersChanged,
    openAppSnackbarNotification
  ]);

  const handleDisconnectEmailProvider = useCallback(async () => {
    try {
      await unlink(auth.currentUser, EmailAuthProvider.PROVIDER_ID);

      onProvidersChanged();
    } catch(e) {
      console.log(e);

      openAppSnackbarNotification({
        message: 'Failed to disconnect email authentication provider',
        variant: 'ERROR'
      });
    }
  }, [onProvidersChanged, openAppSnackbarNotification]);


  const handleChangeEmailDialogOpen = useCallback(() => {
    setChangeEmailDialogOpen(true);
  }, []);

  const handleChangeEmailDialogClose = useCallback(() => {
    setChangeEmailDialogOpen(false);
  }, []);

  const handleChangeEmailDialogSubmit = useCallback(async ({ email }) => {
    handleChangeEmailDialogClose();

    try {
      await updateEmail(auth.currentUser, email);
      await sendEmailVerification({
        handleCodeInApp: true,
        url: `${window.location.origin}${routes.ALL_ITEMS}`
      });

      openAppSnackbarNotification({
        message: 'The email address has been updated. Please check your email inbox.',
        variant: 'INFO'
      });

      onProvidersChanged();
    } catch(e) {
      console.log(e);

      openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      });
    }
  }, [
    handleChangeEmailDialogClose,
    onProvidersChanged,
    openAppSnackbarNotification
  ]);

  const handleChangePasswordDialogOpen = useCallback(() => {
    setChangePasswordDialogOpen(true);
  }, []);

  const handleChangePasswordDialogClose = useCallback(() => {
    setChangePasswordDialogOpen(false);
  }, []);

  const handleChangePasswordDialogSubmit = useCallback(async ({ password, newPassword }) => {
    handleChangePasswordDialogClose();

    const { currentUser } = auth;
    const credential = EmailAuthProvider.credential(
      data.email,
      password
    );

    try {
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);

      openAppSnackbarNotification({
        message: 'The password has been updated.',
        variant: 'INFO'
      });
    } catch(e) {
      console.log(e);

      openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      });
    }
  }, [
    data,
    handleChangePasswordDialogClose,
    openAppSnackbarNotification
  ]);

  return (
    <AuthProviderContainer>
      <div className="auth-provider">
        <MailOutlineIcon className="auth-provider-icon" />

        <div className="auth-provider-name">Email</div>

        {data ?
          <div className="auth-provider-email">{data.email}</div> :
          null
        }

        {!data ?
          <Button
            color="inherit"
            variant="contained"
            size="small"
            onClick={handleConnectEmailDialogOpen}
          >
            Connect
          </Button> :
          allowUnlink ?
            <Button
              size="small"
              color="inherit"
              onClick={handleDisconnectEmailProvider}
            >
              Disconnect
            </Button> :
            null
        }
      </div>

      {data &&
        <div className="provider-actions">
          <Button
            size="small"
            color="inherit"
            variant="outlined"
            onClick={handleChangeEmailDialogOpen}
          >
            Change email
          </Button>

          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={handleChangePasswordDialogOpen}
          >
            Change password
          </Button>
        </div>
      }

      <LinkEmailProviderModal
        open={connectEmailDialogOpen}
        onClose={handleConnectEmailDialogClose}
        onSubmit={handleConnectEmailDialogSubmit}
      />

      <ChangeEmailModal
        open={changeEmailDialogOpen}
        onClose={handleChangeEmailDialogClose}
        onSubmit={handleChangeEmailDialogSubmit}
        oldEmail={data?.email}
      />

      <ChangePasswordModal
        open={changePasswordDialogOpen}
        onClose={handleChangePasswordDialogClose}
        onSubmit={handleChangePasswordDialogSubmit}
      />
    </AuthProviderContainer>
  );
}

PasswordProvider.propTypes = {
  data: PropTypes.object,
  allowUnlink: PropTypes.bool,
  onProvidersChanged: PropTypes.func.isRequired,
  openAppSnackbarNotification: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(PasswordProvider);
