import React, { useMemo, useState, useCallback } from 'react';
import PT from 'prop-types';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Draggable } from 'react-beautiful-dnd';

import TypeSelect from './TypeSelect';
import ContentItemTitle from './ContentItemTitle';
import ContentItemValue from './ContentItemValue';
import RequestAIColumnsDialog from './RequestAIColumnsDialog';

import AIFeatureIcon from '../../../../../../../components/Icons/AIFeature';

import { parameterTypes } from './constants';
import { openAppSnackbarNotification } from '../../../../../../../services/snackbar-notifications/actions';
import { componentNames, TRACK_PREDICTIVE_COLUMNS } from '../../../../../../../analytics/constants';

const { ENUM, QUANTITY } = parameterTypes;
const LINE_GAP = '8';
const ALLOWED_PREDICTIVE_COLUMNS_ENV = ['staging', 'development'].includes(process.env.REACT_APP_ENV);

const ContentItem = ({
  id,
  index,
  columns,
  data,
  readOnly,
  error,
  onRemove,
  onTitleTypeChange,
  onTitleChange,
  onValueTypeChange,
  onValueChange
}) => {
  const { track } = useAnalytics();

  // The predictive column feature is currently
  // only available for the values of "QUANTITY" type.
  const displayPredictionIcon = useMemo(() => {
    // Only for existing parameters
    if(!data.id)
      return false;

    if(data.value.type === QUANTITY.id && !readOnly)
      return true;

    if(data.value.type === 'PREDICTION')
      return true;

    return false;
  }, [data.value.type, data.id, readOnly]);

  const [requestAIColumnOpen, setRequestAIColumnOpen] = useState(false);

  const dispatch = useDispatch();

  const handleRemove = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const handleTitleTypeChange = useCallback(type => {
    onTitleTypeChange(id, type);
  }, [id, onTitleTypeChange]);

  const handleTitleChange = useCallback(value => {
    onTitleChange(id, value);
  }, [id, onTitleChange]);

  const handleValueTypeChange = useCallback(type => {
    onValueTypeChange(id, type);
  }, [id, onValueTypeChange]);

  const handleValueChange = useCallback(value => {
    onValueChange(id, value);
  }, [id, onValueChange]);

  const handleRequestAIColumnOpen = useCallback(() => {
    setRequestAIColumnOpen(true);
  }, []);

  const handleRequestAIColumnClose = useCallback(() => {
    setRequestAIColumnOpen(false);
  }, []);

  const handleRequestAIColumnSuccess = useCallback(() => {
    dispatch(openAppSnackbarNotification({
      variant: 'SUCCESS',
      message: 'Requested Successfully! We’ll be in touch shortly!'
    }));
    track(TRACK_PREDICTIVE_COLUMNS.request, {
      component: componentNames.TABLE_STRUCTURE
    });
    setRequestAIColumnOpen(false);
  }, [dispatch, track]);

  const handleRequestAIColumnError = useCallback((e) => {
    dispatch(openAppSnackbarNotification({
      variant: 'ERROR',
      message: e.message
    }));

    setRequestAIColumnOpen(false);
  }, [dispatch]);

  return (
    <>
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={readOnly}
      >
        {provided => (
          <Box
            display="flex"
            gap={`${LINE_GAP}px`}
            mt="8px"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Box
              flexBasis={`calc(50% - ${LINE_GAP / 2}px)`}
              display="flex"
              alignItems="flex-start"
              flexShrink="0"
              gap="8px"
            >
              <IconButton
                aria-label="drag"
                size="small"
                disabled={readOnly}
                {...provided.dragHandleProps}
              >
                <DragIndicatorIcon fontSize="inherit" />
              </IconButton>

              <TypeSelect
                options={['TEXT', 'LINK']}
                selected={data.title.type}
                disabled={readOnly}
                onChange={handleTitleTypeChange}
              />

              <Box flexGrow="1">
                <ContentItemTitle
                  data={data.title}
                  disabled={readOnly}
                  error={error}
                  onChange={handleTitleChange}
                />
              </Box>
            </Box>

            <Box
              width={`calc(50% - ${LINE_GAP / 2}px)`}
              display="flex"
              alignItems="flex-start"
              flexShrink="0"
              gap="8px"
            >
              <TypeSelect
                options={[
                  'TEXT',
                  'LINK',
                  'QUANTITY',
                  'BOOLEAN',
                  'CALCULATION',
                  ENUM.id
                ]}
                selected={data.value.type}
                disabled={readOnly || !!data.id} // disallow changing the value type of existing parameters
                onChange={handleValueTypeChange}
              />

              <Box
                flexGrow="1"
                minWidth="0"
              >
                <ContentItemValue
                  parameterId={data.id}
                  columns={columns}
                  title={data.title}
                  data={data.value}
                  disabled={readOnly}
                  onChange={handleValueChange}
                />
              </Box>

              {displayPredictionIcon && ALLOWED_PREDICTIVE_COLUMNS_ENV ?
                <>
                  <IconButton
                    size="small"
                    disabled={data.value.type === 'PREDICTION'}
                    sx={{ p: '7px' }}
                    onClick={handleRequestAIColumnOpen}
                  >
                    <AIFeatureIcon
                      type={data.value.type === 'PREDICTION' ? 'active' : 'normal'}
                      sx={{ fontSize: '14px' }}
                    />
                  </IconButton>

                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ my: '6px' }}
                  />
                </> :
                null
              }

              {readOnly ?
                null :
                <IconButton size="small" onClick={handleRemove}>
                  <DeleteOutlineIcon fontSize="inherit" />
                </IconButton>
              }
            </Box>
          </Box>
        )}
      </Draggable>

      {requestAIColumnOpen ?
        <RequestAIColumnsDialog
          parameterId={id}
          onClose={handleRequestAIColumnClose}
          onSuccess={handleRequestAIColumnSuccess}
          onError={handleRequestAIColumnError}
        /> :
        null
      }
    </>
  );
};

ContentItem.propTypes = {
  id: PT.string.isRequired,
  index: PT.number,
  columns: PT.array.isRequired,
  data: PT.shape({
    id: PT.string,
    title: PT.shape({
      type: PT.oneOf(['TEXT', 'LINK']),
      value: PT.oneOfType([PT.string, PT.object])
    }),
    value: PT.shape({
      type: PT.oneOf([
        'TEXT',
        'BOOLEAN',
        'QUANTITY',
        'LINK',
        'CALCULATION',
        ENUM.id
      ]),
      value: PT.oneOfType([
        PT.string,
        PT.shape({
          id: PT.string,
          name: PT.string
        })
      ])
    })
  }).isRequired,
  readOnly: PT.bool,
  error: PT.object,
  onRemove: PT.func,
  onTitleTypeChange: PT.func,
  onTitleChange: PT.func,
  onValueTypeChange: PT.func,
  onValueChange: PT.func
};

export default ContentItem;
