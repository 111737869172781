import React, { useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useAnalytics } from 'use-analytics';

import Typography from '@mui/material/Typography';

import fileDropArea, { FileDropAreaTarget } from '../../../../../HOC/FileDropArea';
import ButtonFile from '../../../../ButtonFile';

import { COLOR_PRIMARY } from '../../../../../styles';
import { componentNames, TRACK_MEASUREMENTS } from '../../../../../analytics/constants';

const FileDropArea = fileDropArea(styled(FileDropAreaTarget)`
  height: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  background: white;
  border: 1px dashed #DCDBDC;
  border-radius: 4px;

  &.on-drag-enter {
    outline-offset: -2px;
    outline: 2px dashed ${COLOR_PRIMARY};
  }
`);

const DragAndDropArea = ({ onAddFile }) => {
  const { track } = useAnalytics();

  const handleFilesAdded = useCallback(files => {
    onAddFile(files[0]);

    track(TRACK_MEASUREMENTS.added, {
      component: componentNames.SIDEBAR
    });
  }, [onAddFile, track]);

  const handleButtonFileClicked = useCallback((ev) => {
    const { files } = ev.target;

    if(!files.length) return;

    onAddFile(Array.from(files)[0]);

    track(TRACK_MEASUREMENTS.added, {
      component: componentNames.SIDEBAR
    });

    ev.target.value = null;
  }, [onAddFile, track]);

  return (
    <FileDropArea onFilesAdded={handleFilesAdded}>
      <Typography
        variant="body2"
        color="rgba(0,0,0,.6)"
      >
        Drop file to this area or
      </Typography>

      <ButtonFile
        variant="outlined"
        size="small"
        onChange={handleButtonFileClicked}
      >
        Choose file
      </ButtonFile>
    </FileDropArea>
  );
};

DragAndDropArea.propTypes = {
  onAddFile: PT.func
};

export default DragAndDropArea;
