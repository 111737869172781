import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DASHBOARDS_WITH_TOOLTIP } from '../constants';

const ViewTooltip = ({
  dashboardItem,
  disabledText,
  onClick,
  children
}) => {

  const hasText = Boolean(DASHBOARDS_WITH_TOOLTIP[dashboardItem.icon]);

  const linkText = DASHBOARDS_WITH_TOOLTIP[dashboardItem.icon]?.text;
  const hasLink = Boolean(DASHBOARDS_WITH_TOOLTIP[dashboardItem.icon]?.link);

  return (
    <Tooltip
      arrow
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Typography
            sx={{
              fontSize: '12px'
            }}
          >
            {disabledText || dashboardItem.label}
          </Typography>

          {hasText && !disabledText ?
            <>
              <Typography
                sx={{
                  fontSize: '12px'
                }}
              >
                {linkText}
              </Typography>

              {hasLink ?
                <Typography
                  onClick={onClick}
                  color="primary"
                  sx={{
                    fontSize: '12px',
                    textDecoration: 'underline',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  Learn more
                </Typography>
                : null
              }
            </>
            : null
          }
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

ViewTooltip.propTypes = {
  dashboardItem: PropTypes.object.isRequired,
  disabledText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default ViewTooltip;
