import React, { useCallback } from 'react';
import PT from 'prop-types';
import omit from 'lodash/omit';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import Input from '../FormElements/Input';
import Textarea from '../FormElements/Textarea';
import MeasurementFile from '../FormElements/MeasurementFile';
import ParsingConfigSelector from '../FormElements/ParsingConfigSelector';
import Graphics from '../FormElements/Graphics/Graphics';

import useMeasurementForm, { FORM_FIELDS } from '../hooks/use-measurement-form';
import useParseFile from '../services/use-parse-file';
import { createBlob } from '../../../../../utils';
import { BACKEND_GRAPHICS_TYPES } from '../../../../../services/graphics/constants';
import { componentNames, TRACK_MEASUREMENTS } from '../../../../../analytics/constants';

const AddMeasurement = ({
  loading,
  error,
  onCancel,
  onSubmit,
  itemId
}) => {
  const {
    state,
    actions: {
      setValue,
      setError,
      addGraphics,
      setGraphicsType,
      setGraphicsConfig,
      removeGraphics,
      validate
    }
  } = useMeasurementForm();
  const [parse, { loading: parsingLoading }] = useParseFile();

  const { track } = useAnalytics();

  const title = state[FORM_FIELDS.TITLE].value;

  const handleTitleChange = useCallback(ev => {
    setValue(FORM_FIELDS.TITLE, ev.target.value);
  }, [setValue]);

  const handleDescriptionChange = useCallback(ev => {
    setValue(FORM_FIELDS.DESCRIPTION, ev.target.value);
  }, [setValue]);

  const handleAddFile = useCallback(({ id, rawFileDownloadURL, rawFilename }) => {
    setValue(FORM_FIELDS.SOURCE_FILE, {
      id,
      downloadURL: rawFileDownloadURL,
      filename: rawFilename
    });

    if(!title)
      setValue(FORM_FIELDS.TITLE, rawFilename);
  }, [setValue, title]);

  const handleRemoveFile = useCallback(() => {
    setValue(FORM_FIELDS.SOURCE_FILE, null);
  }, [setValue]);

  const sourceFile = state[FORM_FIELDS.SOURCE_FILE].value;

  const handleAddChart = useCallback(viewType => {
    addGraphics(viewType);
  }, [addGraphics]);

  const handleParsingConfigSelect = useCallback(async (value) => {
    setValue(FORM_FIELDS.PARSING_CONFIG, value);

    const parsedData = await parse(sourceFile, value);

    if(parsedData) {
      setValue(FORM_FIELDS.PROCESSED_DATA, parsedData);
      handleAddChart(value.parser.viewType);
    } else {
      setError(FORM_FIELDS.PROCESSED_DATA, 'Failed to process the file');
    }
  }, [setValue, setError, handleAddChart, sourceFile, parse]);

  const handleGraphicsTypeChange = useCallback((id, value) => {
    setGraphicsType(id, value);
  }, [setGraphicsType]);

  const handleGrahpicsConfigChange = useCallback((id, value) => {
    setGraphicsConfig(id, value);
  }, [setGraphicsConfig]);

  const handleRemoveGraphics = useCallback(id => {
    removeGraphics(id);
  }, [removeGraphics]);

  const handleAddMesaurement = useCallback(() => {
    const isValid = validate();

    if(!isValid) return;

    const sourceFile = state[FORM_FIELDS.SOURCE_FILE].value;
    const parsingConfig = state[FORM_FIELDS.PARSING_CONFIG].value;
    const processedData = state[FORM_FIELDS.PROCESSED_DATA].value;
    const graphics = state[FORM_FIELDS.GRAPHICS].value;

    const payload = {
      updateTableItemFileId: sourceFile?.id,
      file: {
        tableItemId: itemId,
        title: state[FORM_FIELDS.TITLE].value,
        description: state[FORM_FIELDS.DESCRIPTION].value,
        parserConfigurationId: parsingConfig?.id,
        parserConfigurationCode: parsingConfig?.code,
        processedFile: processedData && createBlob(processedData),
        graphics: graphics
          .filter(
            ({ type }) => Object.hasOwn(BACKEND_GRAPHICS_TYPES, type)
          )
          .map(({ id, type, config }) => ({
            id,
            graphicsType: type,
            config: omit(config, 'axes'),
            mode: 'VIEW_MODE_GRAPH',
            alter: config?.axes ?
              Object
                .entries(config.axes)
                .map(([name, value]) => ({
                  target: name,
                  transform: value.transform
                }))
                .filter(({ transform }) => transform) :
              null
          }))
      }
    };

    onSubmit(payload);

    if (processedData){
      track(TRACK_MEASUREMENTS.create, {
        component: componentNames.SIDEBAR
      });
    }
  }, [validate, state, itemId, onSubmit, track]);

  const parser = state[FORM_FIELDS.PARSING_CONFIG].value?.parser;

  return (
    <Box
      sx={{
        px: '16px',
        py: '12px',
        mb: '16px',
        background: 'rgba(33, 33, 33, 0.05)',
        borderRadius: '7px',
        border: '1px solid #DCDBDC'
      }}
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        mb="16px"
      >
        Add measurement
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}
      >
        <Input
          label="Title"
          value={state[FORM_FIELDS.TITLE].value}
          onChange={handleTitleChange}
          inputProps={{
            maxLength: 200
          }}
          required
          error={state[FORM_FIELDS.TITLE].error}
        />

        <Textarea
          label="Description"
          value={state[FORM_FIELDS.DESCRIPTION].value}
          onChange={handleDescriptionChange}
        />

        <MeasurementFile
          fileInfo={sourceFile}
          defaultTitle={state[FORM_FIELDS.TITLE].value}
          defaultDescription={state[FORM_FIELDS.DESCRIPTION].value}
          error={state[FORM_FIELDS.SOURCE_FILE].error}
          onAddFile={handleAddFile}
          onRemoveFile={handleRemoveFile}
        />

        {sourceFile ?
          <ParsingConfigSelector
            value={state[FORM_FIELDS.PARSING_CONFIG].value}
            filename={sourceFile.filename}
            onSelect={handleParsingConfigSelect}
          /> :
          null
        }

        {parsingLoading ?
          <LinearProgress /> :
          null
        }

        {state[FORM_FIELDS.PROCESSED_DATA].error ?
          <Typography
            variant="body2"
            my="8px"
            color="#d32f2f"
          >
            {state[FORM_FIELDS.PROCESSED_DATA].error}
          </Typography> :
          null
        }

        {state[FORM_FIELDS.GRAPHICS].value.length ?
          <Graphics
            viewType={parser.viewType}
            isComplex={parser.dataType === 'DATA_COMPLEX'}
            graphics={state[FORM_FIELDS.GRAPHICS].value}
            data={state[FORM_FIELDS.PROCESSED_DATA].value}
            onGraphicsConfigChange={handleGrahpicsConfigChange}
            onGraphicsTypeChange={handleGraphicsTypeChange}
            onAddGraphics={handleAddChart}
            onRemoveGraphics={handleRemoveGraphics}
          /> :
          null
        }
      </Box>

      <Divider sx={{ my: '16px' }} />

      {error ?
        <Typography
          variant="body2"
          color="#d32f2f"
          mb="16px"
        >
          Failed to create the measurement.
        </Typography> :
        null
      }

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px'
        }}
      >
        <Button
          size="small"
          disabled={loading}
          onClick={onCancel}
        >
          Close
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          size="small"
          onClick={handleAddMesaurement}
        >
          Create measurement
        </LoadingButton>
      </Box>
    </Box>
  );
};

AddMeasurement.propTypes = {
  loading: PT.bool,
  error: PT.any,
  itemId: PT.string,
  onCancel: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default AddMeasurement;
