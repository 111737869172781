import { initializeApp } from 'firebase/app';
import {
  getAuth,
  OAuthProvider,
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  linkWithPopup,
  unlink,
  verifyPasswordResetCode as authVerifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification as authSendEmailVerification,
  debugErrorMap,
  getAdditionalUserInfo
} from 'firebase/auth';
import { firebase as config } from '../../configs';
import { routes } from './constants';

export const app = initializeApp(config);
export const auth = getAuth(app);

export const firebaseInit = () => {
  return Promise.resolve({
    response: {
      socket: app
    }
  });
};

export const getCurrentUser = () => auth.currentUser;

export async function sendEmailVerification(actionCodeSettings) {
  return authSendEmailVerification(auth.currentUser, actionCodeSettings);
}

export const signUpWithPasswordProvider = (email, password) => createUserWithEmailAndPassword(auth, email, password)
  .then(user => sendEmailVerification({
    handleCodeInApp: true,
    url: `${window.location.origin}${routes.SIGN_IN}`
  })
    .then(() => user)
    .catch(() => user)
  )
  .then(user => ({ response: { userId: user.uid } }))
  .catch(error => {
    const message = debugErrorMap()[error.code.slice('auth/'.length)];

    switch (error.code) {
      case 'auth/email-already-in-use':
      case 'auth/invalid-email':
        return {
          error: {
            ...error,
            message,
            source: 'email'
          }
        };
      case 'auth/weak-password':
        return {
          error: {
            ...error,
            message,
            source: 'password'
          }
        };
      default:
        return {
          error: {
            ...error,
            message,
            source: 'general'
          }
        };
    }
  });

export const signInWithPasswordProvider = (email, password, staySignedIn) => {
  return setPersistence(auth, staySignedIn ? browserLocalPersistence : browserSessionPersistence)
    .then(() => signInWithEmailAndPassword(auth, email, password))
    .then(userCred => ({
      userId: userCred.user.uid,
      isNewUser: getAdditionalUserInfo(userCred).isNewUser
    }))
    .catch(error => ({
      error: {
        ...error,
        message: debugErrorMap()[error.code.slice('auth/'.length)]
      }
    }));
};

export const signInWithOAuthProvider = (providerName, config) => {
  const provider = new OAuthProvider(providerName);

  try {
    const { scope } = config;

    scope.forEach(val => provider.addScope(val));
  } catch(error) {
    console.error(error);

    return Promise.resolve({
      error: {
        message: 'Failed to parse auth provider scope config'
      }
    });
  }

  return signInWithPopup(auth, provider)
    .then(userCred => {
      return {
        response: {
          userId: userCred.user.uid,
          authCredential: userCred,
          isNewUser: getAdditionalUserInfo(userCred).isNewUser
        }
      };
    })
    .catch(error => ({ error }));
};

export const sendPasswordResetEmail = email =>
  authSendPasswordResetEmail(auth, email, {
    handleCodeInApp: true,
    url: `${window.location.origin}${routes.SIGN_IN}`
  })
    .then(() => ({
      response: {
        message: `Please check your email ${email}.`
      }
    }))
    .catch(error => ({ error }));

export const logout = () => signOut(auth)
  .then(() => ({ response: { code: 'success' } }))
  .catch(error => ({ error }));

export function* linkProvider(providerId) {
  let provider;

  switch (providerId) {
    case 'google.com': {
      provider = GoogleAuthProvider();
      break;
    }

    case 'facebook.com': {
      provider = new FacebookAuthProvider();
      break;
    }

    default:
      return { error: 'Unhandle provider id' };
  }

  try {
    const result = yield linkWithPopup(auth.currentUser, provider);
    return { response: result.user.providerData };
  } catch (error) {
    return { error };
  }
}

export function* unlinkProvider({ providerId, user }) {
  if (!providerId) return { error: 'Provider id is required' };

  try {
    yield unlink(user, providerId);
    return { response: 'ok' };
  } catch (error) {
    return { error };
  }
}

export const verifyPasswordResetCode = async (actionCode) => {
  try {
    return await authVerifyPasswordResetCode(auth, actionCode);
  } catch (e) {
    return null;
  }
};

export const resetPassword = async (email, actionCode, newPassword, continueUrl) => {
  try {
    await verifyPasswordResetCode(auth, actionCode);
    await confirmPasswordReset(auth, actionCode, newPassword);
    return continueUrl;
  } catch (e) {
    return null;
  }
};

export const recoverEmail = async(oobCode) => {
  try {
    const resp = await checkActionCode(auth, oobCode);
    const email = resp?.data?.email;
    await applyActionCode(auth, oobCode);
    return email;
  } catch (e) {
    return null;
  }
};

export const verifyEmail = async(oobCode) => {
  try {
    const resp = await checkActionCode(auth, oobCode);
    const email = resp?.data?.email;
    await applyActionCode(auth, oobCode);
    return email;
  } catch (e) {
    return null;
  }
};

export const sendPasswordReset = async (email) => {
  try {
    return await authSendPasswordResetEmail(auth, email);
  } catch (e){
    return null;
  }
};
