import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import { browserSessionPersistence, browserLocalPersistence, setPersistence } from 'firebase/auth';

import { auth } from '../../../services';

import AuthSettingsContainer from './styles';
import AuthProvider from './AuthProvider';
import KeepSignedIn from './KeepSignedIn';
import IdleSignOut from './IdleSignOut';

import { browserStorage } from '../../../utils';

import { CHANGE_IDLE_SIGN_OUT, IDLE_SIGN_OUT_VALUES } from './gql';
import { componentNames, TRACK_PROFILE_SETTINGS } from '../../../analytics/constants';

const AUTH_PROVIDERS = [
  'PASSWORD',
  'GOOGLE'
];

function AuthSettings({
  userId,
  providers,
  onProvidersChanged,
  idleSignOut
}) {

  const [keepSignedin, setKeepSignedin] = useState(browserStorage.get('keepMeLoggedIn') || false);

  const { data } = useQuery(IDLE_SIGN_OUT_VALUES);

  const [changeIdleSignOut] = useMutation(CHANGE_IDLE_SIGN_OUT);

  const { track } = useAnalytics();

  const idleSignOutValues = data?.idleSignOutValues;

  const handleProviderChange = useCallback(() => {
    onProvidersChanged();

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.AUTHENTICATION
    });
  }, [onProvidersChanged, track]);

  const handleKeepSignedinChange = useCallback(async value => {
    setKeepSignedin(value);
    browserStorage.set('keepMeLoggedIn', value);

    try {
      await setPersistence(auth, value ? browserLocalPersistence : browserSessionPersistence);
    } catch(e) {
      console.log(e);
    }

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.AUTHENTICATION
    });
  }, [track]);

  const handleIdleSignOutChange = useCallback(async value => {
    try {
      await changeIdleSignOut({
        variables: {
          input: {
            idleSignOut: value
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProfile: {
            __typename: 'Viewer',
            id: userId,
            idleSignOut: value
          }
        }
      });
    } catch (e) {
      console.error(e);
    }

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.AUTHENTICATION
    });
  }, [changeIdleSignOut, track, userId]);

  return (
    <AuthSettingsContainer>
      {AUTH_PROVIDERS.map(providerId => (
        <AuthProvider
          key={providerId}
          provider={providerId}
          data={providers.find(provider => provider.providerId === providerId)}
          allowUnlink={providers.length > 1}
          onProvidersChanged={handleProviderChange}
        />
      ))}

      <KeepSignedIn
        value={keepSignedin}
        onChange={handleKeepSignedinChange}
      />

      <IdleSignOut
        period={idleSignOut}
        idleSignOutValues={idleSignOutValues}
        onChange={handleIdleSignOutChange}
      />
    </AuthSettingsContainer>
  );
}

AuthSettings.propTypes = {
  userId: PropTypes.string.isRequired,
  providers: PropTypes.array.isRequired,
  onProvidersChanged: PropTypes.func.isRequired,
  idleSignOut: PropTypes.number
};

export default AuthSettings;
