import React, {
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useReducer
} from 'react';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../../services/session/api';

const UserContext = React.createContext({});

const initialState = {
  ready: false,
  user: null,
  emailVerified: null
};

const actions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  VERIFY_EMAIL: 'VERIFY_EMAIL'
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SIGN_IN:
      return {
        ...state,
        ready: true,
        user: action.payload.user,
        emailVerified: action.payload.user?.emailVerified ?? null
      };

    case actions.SIGN_OUT:
      return {
        ...state,
        ready: true,
        user: null,
        emailVerified: null
      };

    case actions.VERIFY_EMAIL:
      return {
        ...state,
        emailVerified: true
      };

    default:
      return state;
  }
};

export const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if(authUser)
        dispatch({
          type: actions.SIGN_IN,
          payload: { user: authUser }
        });
      else
        dispatch({ type: actions.SIGN_OUT });
    });
  }, []);

  // FIXME: consider a better API for verification handling
  const refresh = useCallback(() => {
    if (state.user)
      dispatch({ type: actions.VERIFY_EMAIL });
  }, [state.user]);

  const memoValue = useMemo(() => ({
    ...state,
    refresh
  }), [refresh, state]);

  return (
    <UserContext.Provider value={memoValue}>
      {props.children}
    </UserContext.Provider>);
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuth = () => useContext(UserContext);
