import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const SEND_PREDICTIVE_COLS_REQUEST = gql`
  mutation SendPredictiveColumnEmailRequest($parameterId: ID!, $note: String) {
    sendPredictiveColumnEmailRequest(tableParameterId: $parameterId, note: $note)
  }
`;

const RequestAIColumnsDialog = ({ parameterId, onClose, onSuccess, onError }) => {
  const [note, setNote] = useState('');

  const [sendEmailRequest, { loading }] = useMutation(SEND_PREDICTIVE_COLS_REQUEST);

  const handleNoteChange = useCallback((ev) => {
    setNote(ev.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await sendEmailRequest({
        variables: {
          parameterId,
          note
        }
      });

      onSuccess();
    } catch(e) {
      onError(e);
    }
  }, [parameterId, note, sendEmailRequest, onSuccess, onError]);

  return (
    <Dialog
      open
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '450px'
        }
      }}
      onClose={onClose}
    >
      <DialogContent sx={{ p: 0 }}>
        <Card elevation={0}>
          <CardMedia
            sx={{ height: '160px' }}
            image="/img/request-ai-col-dialog-bg.png"
          />

          <CardContent
            sx={{
              '&.MuiCardContent-root:last-child': {
                pb: '8px'
              }
            }}
          >
            <Typography
              variant="h6"
              fontSize="18px"
              mb="16px"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              Define a Predictive Column
              {' '}
              <Typography
                component="span"
                sx={{
                  display: 'inline-flex',
                  p: '3px',
                  borderRadius: '2px',
                  background: 'linear-gradient(221deg, #54B9D3 28.6%, #3263F6 84.74%)',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '1em',
                  color: 'white'
                }}
              >
                New!
              </Typography>
            </Typography>

            <Typography variant="body2" mb="10px">
              Predictive Columns use Machine Learning models to predict empty values. They can be used to predict experimental outcomes, material properties and more!
            </Typography>

            <Typography variant="body2" mb="10px" color="rgba(0,0,0,.6)">
              Tell us what are you trying to predict?
            </Typography>

            <InputBase
              value={note}
              multiline
              minRows={3}
              maxRows={5}
              fullWidth
              sx={{
                border: '1px solid #D6D6D6',
                borderRadius: '4px',
                p: '2px 8px',
                fontSize: '14px'
              }}
              onChange={handleNoteChange}
            />
          </CardContent>
        </Card>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          Send Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

RequestAIColumnsDialog.propTypes = {
  parameterId: PT.string,
  onClose: PT.func,
  onSuccess: PT.func,
  onError: PT.func
};

export default RequestAIColumnsDialog;
