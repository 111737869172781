import { ReactComponent as Correlations } from '../../../../components/Icons/img/correlations.svg';
import { ReactComponent as Histogram } from '../../../../components/Icons/img/histogram.svg';
import { ReactComponent as MeasurementOverlay } from '../../../../components/Icons/img/measurement_overlay.svg';
import { ReactComponent as Regression } from '../../../../components/Icons/img/regression.svg';
import { ReactComponent as TimeSeries } from '../../../../components/Icons/img/time_series.svg';
import { ReactComponent as ViolinPlot } from '../../../../components/Icons/img/violin_plot.svg';
import TableIcon from '../../../../components/Icons/TableIcon';
import VIDEO_LINKS from '../../../../components/VideoHelperDialog/constants';

export const icons = {
  'CORRELATIONS': Correlations,
  'HISTOGRAM': Histogram,
  'MEASUREMENT_OVERLAY': MeasurementOverlay,
  'REGRESSION': Regression,
  'TIME_SERIES': TimeSeries,
  'VIOLIN_PLOT': ViolinPlot,
  'TABLE': TableIcon,
};

export const DASHBOARDS_WITH_TOOLTIP = {
  CORRELATIONS: {
    link: VIDEO_LINKS.CORRELATIONS,
    text: 'A correlations plot visually displays the strength and direction of relationships between multiple variables, helping to quickly identify patterns, trends, and potential interdependencies within a dataset.'
  },
  HISTOGRAM: {
    text: 'The Histogram dashboard allows you to visualize the distribution of protocol parameters.'
  },
  MEASUREMENT_OVERLAY: {
    text: 'The Measurement Overlay dashboard allows you to compare between different measurements by overlaying them in a single plot.'
  },
  //PREDICTIVE_MODELING
  REGRESSION: {
    text: 'The Predictive Modeling dashboard helps build models to predict a target property based on other item properties.'
  },
  TIME_SERIES: {
    text: 'The Time Series dashboard allows you to plot item properties over time.'
  },
  VIOLIN_PLOT: {
    link: VIDEO_LINKS.VIOLIN_PLOT,
    text: 'A violin plot provides a detailed visual summary of the distribution of a dataset with categorical parameters.'
  },
};
