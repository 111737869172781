import {
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged
} from 'firebase/auth';
import { auth } from './api';
import {
  call,
  put,
  select,
  take,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import { fetchEntity } from '../../sagas';
import * as api from './api';
import {
  getCurrentUser,
} from './selectors';
import * as userActions from './actions';
import {
  REQUEST,
  SUCCESS,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  SIGN_IN,
  SIGN_OUT,
  PASSWORD_PROVIDER,
  OAUTH_PROVIDER,
  LAST_VISITED_PAGE,
  SET_AUTH_PERSISTENCE
} from './constants';
import { action } from '../../actions';
import { browserStorage, isMobile } from '../../utils';
import client from '../apollo-client';
import { GET_VIEWER } from './gql';
import analytics from '../../analytics';

const fetchSignUp = authProviderType => {
  switch(authProviderType) {
    case PASSWORD_PROVIDER:
      return fetchEntity.bind(null, userActions.signUpHandlers, api.signUpWithPasswordProvider);
    case OAUTH_PROVIDER:
      return fetchEntity.bind(null, userActions.signUpHandlers, api.signInWithOAuthProvider);
    default:
      return () => Promise.reject({
        message: `Unknown auth provider type: ${authProviderType}`
      });
  }
};
const fetchSignIn = authProviderType => {
  switch(authProviderType) {
    case PASSWORD_PROVIDER:
      return fetchEntity.bind(null, userActions.signInHandlers, api.signInWithPasswordProvider);
    case OAUTH_PROVIDER:
      return fetchEntity.bind(null, userActions.signInHandlers, api.signInWithOAuthProvider);
    default:
      return () => Promise.reject({
        message: `Unknown auth provider type: ${authProviderType}`
      });
  }
};
const fetchResetPasswordEmail = fetchEntity.bind(null,
  userActions.forgotPasswordHandlers,
  api.sendPasswordResetEmail
);
const fetchLogout = fetchEntity.bind(null,
  userActions.logoutHandlers,
  api.logout
);
const fetchFirebase = fetchEntity.bind(null,
  userActions.firebaseInitHandlers,
  api.firebaseInit
);

const fetchLinkProvider = fetchEntity.bind(null,
  userActions.linkProviderHandlers,
  api.linkProvider
);
const fetchUnlinkProvider = fetchEntity.bind(null,
  userActions.unlinkProviderHandlers,
  api.unlinkProvider
);

export const listeners = [
  fork(firebaseInit),
  takeLatest(userActions.firebaseInitTypes[SUCCESS], firebaseAuthWatch),
  takeLatest(userActions.fetchSignUpTypes[REQUEST], onFetchSignUp),
  takeLatest(userActions.fetchSignInTypes[REQUEST], onFetchSignIn),
  takeLatest(USER_SIGNED_IN, onUserSignedIn),
  takeLatest(USER_SIGNED_OUT, onUserSignedOut),
  takeLatest(userActions.fetchCreateUserTypes[SUCCESS], onUserSignedIn),
  takeLatest(userActions.userForgotPasswordTypes[REQUEST], sendPasswordResetEmail),
  takeLatest(userActions.userLogoutTypes[REQUEST], logout),
  takeLatest(userActions.linkProviderTypes[REQUEST], linkProvider),
  takeLatest(userActions.unlinkProviderTypes[REQUEST], unlinkProvider),
  takeLatest(SET_AUTH_PERSISTENCE, onSetAuthPersistence)
];

function* firebaseInit() {
  yield put(userActions.firebaseInitHandlers.request());
  yield call(fetchFirebase);
}

function* firebaseAuthWatch() {
  const channel = yield call(listenAuth);

  while (true) {
    const payload = yield take(channel);
    yield put(payload);
  }
}

function listenAuth() {
  return eventChannel(emit => {
    const unsubscribe = onAuthStateChanged(auth, authUser => {
      if(typeof window.Intercom === 'function')
        window.Intercom('shutdown');

      if (authUser) {
        emit(action(USER_SIGNED_IN, { authUser }));

        if(typeof window.Intercom === 'function')
          window.Intercom('boot', {
            app_id: 'f5fczxgu',
            email: authUser.email,
            name: authUser.displayName,
            user_id: authUser.uid,
            alignment: 'right'
          });
      } else {
        emit(action(USER_SIGNED_OUT));

        client.resetStore();

        if(typeof window.Intercom === 'function')
          window.Intercom('boot', {
            app_id: 'f5fczxgu',
            alignment: 'right'
          });

        analytics.reset();
      }
    });

    return unsubscribe; // unsubscribe
  });
}

function* onFetchSignUp({ authProviderType, params }) {
  const user = yield select(getCurrentUser);

  if (!user) {
    yield call(fetchSignUp(authProviderType), ...params);
  }
}

function* onFetchSignIn({ authProviderType, params }) {
  const user = yield select(getCurrentUser);

  if (!user) {
    yield call(fetchSignIn(authProviderType), ...params);
  }
}

function* onUserSignedIn() {
  const authUser = api.getCurrentUser();

  const viewerResponse = yield client.query({
    query: GET_VIEWER,
    fetchPolicy: 'network-only'
  });

  const user = viewerResponse.data?.viewer;

  yield put(userActions.setCurrentUser(user));
  yield put(action(SIGN_IN, { authUser, user }));

  if (user) {
    yield put(userActions.setMobileView(isMobile()));

    analytics.identify(user.id, { authUser, user });
  }
}

function* onUserSignedOut() {
  yield put(action(SIGN_OUT));

  browserStorage.delete(LAST_VISITED_PAGE);
}

function* sendPasswordResetEmail({ email }) {
  const user = yield select(getCurrentUser);

  if (!user) {
    yield call(fetchResetPasswordEmail, email);
  }
}

function* logout() {
  yield call(fetchLogout);
}

function* linkProvider({ provider }) {
  yield call(fetchLinkProvider, provider);
}

function* unlinkProvider(data) {
  yield call(fetchUnlinkProvider, data);
}

function * onSetAuthPersistence({ staySignedIn }) {
  yield call(setPersistence, auth, staySignedIn ? browserLocalPersistence : browserSessionPersistence);
}
